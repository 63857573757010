import axios from 'axios';
import Cookies from 'js-cookie';

// action types
export const SAVE_AUTH = 'login';
export const LOGOUT = 'logout';
export const UPDATE_USER = 'updateCredit';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setAuth';

function getUserLevel(userInfo) {
  let res = {};
  res['fe'] = false;
  res['fem'] = false;
  res['oto1'] = false;
  res['oto1m'] = false;
  res['oto2'] = false;
  res['oto3'] = false;
  res['oto4'] = false;
  res['oto4v'] = false;
  res['oto4s'] = false;
  res['otom'] = false;
  res['poamin'] = false;
  res['super'] = false;

  if (userInfo && Number(userInfo.originator) !== 0) {
    if (userInfo.type === 1 || userInfo.type === 3) {
      res['fe'] = true;
    }
    if (userInfo.type === 2 || userInfo.type === 3) {
      res['oto3'] = true;
    }
    return res;
  }

  if (userInfo && userInfo.level) {
    let levels = userInfo.level.split(',');
    levels.forEach(element => {
      const l = element.toUpperCase();
      if (l === 'FE') {
        res['fe'] = true;
      } else if (l === 'FEM') {
        res['fem'] = true;
      } else if (l === 'OTO1') {
        res['oto1'] = true;
      } else if (l === 'OTO1M') {
        res['oto1m'] = true;
      } else if (l === 'OTO2') {
        res['oto2'] = true;
      } else if (l === 'OTO3') {
        res['oto3'] = true;
      } else if (l === 'OTO4') {
        res['oto4'] = true;
      } else if (l === 'OTO4V') {
        res['oto4v'] = true;
      } else if (l === 'OTO4S') {
        res['oto4s'] = true;
      } else if (l === 'OTOM') {
        res['otom'] = true;
      } else if (l === 'POADMIN') {
        res['poadmin'] = true;
      } else if (l === 'SUPER') {
        res['super'] = true;
      }
    });
  }
  return res;
}

const userc = localStorage.getItem('user');

const state = {
  user:
    'undefined' === typeof userc
      ? null
      : JSON.parse(localStorage.getItem('user')),
  token: Cookies.get('token'),
  userLevel:
    'undefined' === typeof userc
      ? []
      : getUserLevel(JSON.parse(localStorage.getItem('user')))
};

const getters = {
  currentUser: state => state.user,
  currentLevel: state => state.userLevel,
  token: state => state.token,
  check: state => state.user !== null
};

const actions = {
  [SAVE_AUTH](context, user) {
    context.commit(SET_AUTH, user);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [UPDATE_USER](context, user) {
    context.commit(UPDATE_USER, user);
  }
};

const mutations = {
  [SET_AUTH](state, data) {
    state.user = data.user;
    state.userLevel = getUserLevel(data.user);
    localStorage.setItem('user', JSON.stringify(data.user));
    Cookies.set('token', data.access_token, { expires: data.expires_in });
  },
  [PURGE_AUTH](state) {
    state.user = null;
    state.userLevel = [];
    state.token = null;
    localStorage.removeItem('user');
    Cookies.remove('token');
  },
  [UPDATE_USER](state, data) {
    state.user = data;
    state.userLevel = getUserLevel(data);
    localStorage.setItem('user', JSON.stringify(data));
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
