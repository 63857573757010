import axios from 'axios';
import store from './store';
import router from '../../router';
import Swal from 'sweetalert2';
import { PURGE_AUTH } from './store/auth.module';

// // Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token'];
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`;
  }

  const locale = store.getters['lang/locale'];
  if (locale) {
    request.headers.common['Accept-Language'] = locale;
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request;
});

// Response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    // if (status >= 500) {
    //   Swal.fire({
    //     title: 'Error',
    //     text: JSON.stringify(error.response.data.message),
    //     reverseButtons: true,
    //     confirmButtonText: 'ok',
    //     cancelButtonText: 'cancel'
    //   });
    // }

    if (status === 401 && store.getters.check) {
      store.commit(PURGE_AUTH);
      router.push({ name: 'login' });
    } else {
      return error.response;
    }
  }
);
