// Keenthemes plugins
import KTUtil from "@/assets/js/components/util.js";
window.KTUtil = KTUtil;

import KTCard from "@/assets/js/components/card.js";
window.KTCard = KTCard;

import KTCookie from "@/assets/js/components/cookie.js";
window.KTCookie = KTCookie;

import KTHeader from "@/assets/js/components/header.js";
window.KTHeader = KTHeader;


import KTMenu from "@/assets/js/components/menu.js";
window.KTMenu = KTMenu;

import KTOffcanvas from "@/assets/js/components/offcanvas.js";
window.KTOffcanvas = KTOffcanvas;

import KTScrolltop from "@/assets/js/components/scrolltop.js";
window.KTScrolltop = KTScrolltop;

import KTToggle from "@/assets/js/components/toggle.js";
window.KTToggle = KTToggle;


// Metronic layout base js

import KTLayoutContent from "@/assets/js/layout/base/content.js";
window.KTLayoutContent = KTLayoutContent;

import KTLayoutFooter from "@/assets/js/layout/base/footer.js";
window.KTLayoutFooter = KTLayoutFooter;

import KTLayoutHeader from "@/assets/js/layout/base/header.js";
window.KTLayoutHeader = KTLayoutHeader;

import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
window.KTLayoutHeaderMenu = KTLayoutHeaderMenu;

import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
window.KTLayoutHeaderTopbar = KTLayoutHeaderTopbar;


import KTLayoutSubheader from "@/assets/js/layout/base/subheader.js";
window.KTLayoutSubheader = KTLayoutSubheader;

// Metronic layout extended js


import KTLayoutQuickNotifications from "@/assets/js/layout/extended/quick-notifications.js";
window.KTLayoutQuickNotifications = KTLayoutQuickNotifications;

import KTLayoutQuickSearch from "@/assets/js/layout/extended/quick-search.js";
window.KTLayoutQuickSearch = KTLayoutQuickSearch;

import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
window.KTLayoutQuickUser = KTLayoutQuickUser;

import KTLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";
window.KTLayoutScrolltop = KTLayoutScrolltop;

import KTLayoutSearch from "@/assets/js/layout/extended/search.js";
window.KTLayoutSearch = KTLayoutSearch;
