import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import axios from 'axios';
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/inline-svg';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';

import '@/core/services/axios.service';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import AudioVisual from 'vue-audio-visual';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('VueSlider', VueSlider);

import lockVideo from '@/view/layout/components/LockVideo';
Vue.component('lock-video', lockVideo);

import tutorialVideo from '@/view/layout/components/TutorialVideo';
Vue.component('tutorial-video', tutorialVideo);

Vue.use(Loading, {
  loader: 'dots',
  color: '#FFFF73',
  backgroundColor: '#3e3838'
});

Vue.use(AudioVisual);
library.add(fas);
library.add(fab);

Vue.component('fa-icon', FontAwesomeIcon);

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

Vue.component('vue-draggable-resizable', VueDraggableResizable);

import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

import Clipboard from 'v-clipboard';
Vue.use(Clipboard);

import vSelect from'vue-select';
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

import VueRecord from '@codekraft-studio/vue-record'
Vue.use(VueRecord)


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
