<template>
  <b-card :title="title" :sub-title="subTitle">
    <b-embed
      type="iframe"
      aspect="16by9"
      :src="videoUrl ? videoUrl : defaeultVideo"
      allowfullscreen
    >
    </b-embed>
    <p class="mt-4 p-4 mb-0" v-html="note"></p>
  </b-card>
</template>
<style scoped></style>
<script>
export default {
  name: 'tutorialVideo',
  props: {
    title: { type: String, default: '' },
    subTitle: { type: String, default: '' },
    videoUrl: { type: String, default: null },
    note: { type: String, default: null }
  },
  data() {
    return {
      defaeultVideo: '/video/intro.mp4'
    };
  }
};
</script>
