<template>
  <div id="com-lock-video">
    <div class="row">
      <div class="py-0 col-md-2"></div>
      <div class="py-0 col-md-8">
        <b-card
          :title="'Welcome to ' + title"
          sub-title="You need to purchase for using this feature."
        >
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="videoUrl ? videoUrl : defaultVideo"
            allowfullscreen
          >
          </b-embed>
        </b-card>
      </div>
    </div>
    <div class="row mt-7 mb-3">
      <div
        class="py-0 col-md-12 d-flex justify-content-center align-items-center"
      >
        <fa-icon :icon="['fas', 'unlock-alt']" class="unlock-icon"></fa-icon>
      </div>
      <div
        class="py-0 col-md-12 mt-3 d-flex justify-content-center align-items-center"
      >
        <a
          class="btn btn-dark unlock-button"
          :href="purchaseUrl"
          target="_blank"
          >PURCHASE TO UNLOCK THIS UPGRADE</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
#com-lock-video .unlock-icon {
  font-size: 3rem;
}

#com-lock-video .unlock-button {
  font-size: 1.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 30px;
}
</style>

<script>
export default {
  name: 'lockVideo',
  props: {
    videoUrl: { type: String, default: null },
    purchaseUrl: { type: String, default: null },
    title: { type: String, default: '' }
  },
  data() {
    return {
      defaultVideo: '/video/intro.mp4'
    };
  }
};
</script>
