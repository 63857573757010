import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import guest from './core/services/middleware/guest';

Vue.use(Router);

const routes = [
  {
    path: '/certification',
    beforeEnter: (to, from, next) => {
      window.location = `${process.env.MIX_CERTIFICATION_URL}`;
    }
  },
  {
    path: '/unsubscribe',
    component: () => import('@/view/pages/Unsubscribe.vue')
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/Login')
  },
  {
    path: '/forgot',
    name: 'forgot',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/Forgot')
  },
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      window.location = `${process.env.MIX_APP_URL}/home`;
    }
  },
  {
    path: '/appsumo',
    name: 'register',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/Register')
  },
  {
    path: '/deal',
    name: 'dealmirror',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/DealMirror')
  },
  {
    path: '/streamr/appsumo',
    name: 'streamrreg',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/StreamrReg')
  },
  {
    path: '/streamr/appsumo/*',
    name: 'streamrregcode',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/StreamrReg')
  },
  {
    path: '/voicely/appsumo',
    name: 'voicelyreg',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/VoicelyReg')
  },
  {
    path: '/voicely/appsumo/*',
    name: 'voicelyregcode',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/VoicelyReg')
  },
  {
    path: '/appsumo/*',
    name: 'registercode',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/Register')
  },
  {
    path: '/support',
    name: 'supportout',
    component: () => import('@/view/pages/Support.vue')
  },
  {
    path: '/credits',
    name: 'creditout',
    component: () => import('@/view/pages/CreditOut.vue')
  },
  {
    path: '/blog',
    name: 'blogs',
    component: () => import('@/view/pages/blog/Blogs.vue'),
  },
  {
    path: '/blog/es',
    name: 'blogs-es',
    component: () => import('@/view/pages/blog/BlogsEs.vue')
  },
  {
    path: '/blog/fr',
    name: 'blogs-fr',
    component: () => import('@/view/pages/blog/BlogsFr.vue')
  },
  {
    path: '/blog/pt',
    name: 'blogs-pt',
    component: () => import('@/view/pages/blog/BlogsPt.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/view/pages/error/Error.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/view/pages/Verify.vue')
  },
  {
    path: '/legal',
    name: 'legalout',
    component: () => import('@/view/pages/Legal.vue'),
    children: [
      {
        path: 'privacy',
        name: 'privacyouter',
        component: () => import('@/view/pages/legal/Privacy.vue')
      },
      {
        path: 'disclaimer',
        name: 'disclaimerout',
        component: () => import('@/view/pages/legal/Disclaimer.vue')
      },
      {
        path: 'terms',
        name: 'termsout',
        component: () => import('@/view/pages/legal/Terms.vue')
      }
    ]
  },
  {
    path: '/password/reset/:token',
    beforeEnter: guest,
    component: () => import('@/view/pages/auth/Reset.vue')
  },
  {
    path: '/',
    component: () => import('@/view/pages/auth/Login'),
    beforeEnter: guest,
    redirect: '/login'
  },
  {
    path: '/app',
    redirect: '/app/welcome',
    component: () => import('@/view/layout/Layout'),
    children: [
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/view/pages/Welcome.vue')
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('@/view/pages/Resource.vue')
      },

      {
        path: 'membership',
        name: 'membership',
        component: () => import('@/view/pages/Membership.vue'),
        children: [
          {
            path: 'vidtoon',
            name: 'vidtoon',
            component: () => import('@/view/pages/membership/Vidtoon.vue')
          },
          {
            path: 'club',
            name: 'club',
            component: () => import('@/view/pages/membership/Club.vue')
          },
          {
            path: 'voicely',
            name: 'voicely',
            component: () => import('@/view/pages/membership/Voicely.vue')
          },
          {
            path: 'streamr',
            name: 'streamr',
            component: () => import('@/view/pages/membership/Streamr.vue')
          },
          {
            path: 'reseller',
            name: 'reseller',
            component: () => import('@/view/pages/membership/Reseller.vue')
          },
          {
            path: 'user-admin',
            name: 'user-admin',
            component: () => import('@/view/pages/membership/UserAdmin.vue')
          },
          {
            path: 'gen-coupon',
            name: 'gen-coupon',
            component: () => import('@/view/pages/membership/Coupon.vue')
          }
          // {
          //   path: 'meeet',
          //   name: 'meeet',
          //   component: () => import('@/view/pages/membership/Meeet.vue')
          // }
        ]
      },
      {
        path: 'mine',
        name: 'mine',
        component: () => import('@/view/pages/Mine.vue'),
        children: [
          {
            path: 'myresource',
            name: 'myresource',
            component: () => import('@/view/pages/mine/MyResource')
          },
          {
            path: 'myvoice',
            name: 'myvoice',
            component: () => import('@/view/pages/mine/MyVoice.vue')
          },
          {
            path: 'myfavourite',
            name: 'myfavourite',
            component: () => import('@/view/pages/mine/MyFavourite.vue')
          },
          {
            path: 'myaivoice',
            name: 'myaivoice',
            component: () => import('@/view/pages/mine/MyAIVoice.vue')
          }
        ]
      },
      {
        path: 'training',
        name: 'traning',
        component: () => import('@/view/pages/Training.vue')
      },
      {
        path: 'support',
        name: 'support',
        component: () => import('@/view/pages/SupportIn.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/view/pages/Profile.vue')
      },
      {
        path: 'credit',
        name: 'credit',
        component: () => import('@/view/pages/Credits.vue')
      },
      {
        path: 'earn',
        name: 'earn',
        component: () => import('@/view/pages/Earn.vue')
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/view/pages/Notifications.vue')
      },
      {
        path: 'broadcasts',
        name: 'broadcasts',
        component: () => import('@/view/pages/Broadcast.vue')
      },
      {
        path: 'legal',
        name: 'legal',
        component: () => import('@/view/pages/Legal.vue'),
        children: [
          {
            path: 'privacy',
            name: 'privacy',
            component: () => import('@/view/pages/legal/Privacy.vue')
          },
          {
            path: 'disclaimer',
            name: 'Disclaimer',
            component: () => import('@/view/pages/legal/Disclaimer.vue')
          },
          {
            path: 'terms',
            name: 'Terms',
            component: () => import('@/view/pages/legal/Terms.vue')
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/view/pages/error/Error.vue')
  }
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500);
});

export default router;
